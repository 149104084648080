.container {
  margin: 0 auto;
}

@media (min-width:1440px) {
  .container {
    max-width: 1248px;
  }
}

@media(max-width:390px), (min-width:390px) {
  .container {
    min-width: 342px;
    padding-left: 24px;
    padding-right: 24px;
  }
}
