:root {
  --brown: #958060;
  --background: #f9f9f9;
  --white: #f7f7f7;
  --dark-gray: #545454;
  --gray: #686868;
  --red: #af2121;
  --green: #2e7f21;
  --gray-disabled: #a2a2a2;
  --brown-hover: #604b2b;
  --brown-focused: #c8b18d;
  --open-sans: "Open Sans", sans-serif;
  --montserrat: "Montserrat", sans-serif;
  --darkbrown: #685843;

}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  color: var(--gray);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-block-start: 0;
  padding-block-end: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
}

button {
  border: none;
  background-color: transparent;
}

a {
  color: var(--brown);
  font-weight: 600;
  text-decoration: none;
}

li {
  list-style: none;
}