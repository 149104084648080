.container {
  margin: 0 auto 200px;
  padding: 0 24px 0;
  max-width: 1248px;
  width: 100%;
}
.titleContainer {
  margin: 0 auto 80px;
  position: relative;
  max-width: max-content;
}
.titleContainer::before {
  display: block;
  position: absolute;
  top: 90px;
  left: -30px;
  content: "";
  width: 370px;
  height: 6px;
  background-color: var(--brown);
}
.title {
  position: relative;
  font-size: 60px;
  line-height: 90px;
  font-weight: 800;
  color: var(--dark-gray);
  text-transform: uppercase;
}
.title::before,
.title::after {
  display: block;
  position: absolute;
  top: 40px;
  content: "";
  width: 12px;
  height: 12px;
  background-color: var(--brown);
  border-radius: 50%;
}
.title::before {
  left: -20px;
}
.title::after {
  right: -20px;
}

.info {
  margin: 0 auto;
  display: grid;
  align-items: center;
  justify-items: center;
  justify-content: center;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 32px;
  grid-row-gap: 32px;
}
.img1 {
  grid-area: 1 / 1 / 2 / 3;
}
.text1 {
  grid-area: 1 / 3 / 2 / 7;
}
.img2 {
  grid-area: 2 / 5 / 3 / 7;
}
.text2 {
  grid-area: 2 / 1 / 3 / 5;
}
.img3 {
  grid-area: 3 / 1 / 4 / 3;
}
.text3 {
  grid-area: 3 / 3 / 4 / 7;
}

.img1,
.img2,
.img3 {
  max-width: 501px;
  width: 100%;
}
.text1,
.text2,
.text3 {
  max-width: 608px;
  width: 100%;
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  font-family: var(--montserrat);
}
.text1 span,
.text2 span,
.text3 span{
  font-family: var(--open-sans);
 color: var(--brown);
 font-size: 27px;
 font-weight: 700;
}

@media (max-width: 1081px) {
  .container{
    margin-bottom: 150px;
  }
  .title{
    font-size: 50px;
  }
  .titleContainer::before{
    width: 312px;
  }
}
@media (max-width: 768px) {

  .title{
    font-size: 40px;
  }
  .titleContainer{
    margin-bottom: 50px;
  }
  .titleContainer::before{
    width: 267px;
    top: 80px;
  }
  .text1,
  .text2,
  .text3 {
    font-size: 16px;
    line-height: 28px;
  }
}
@media (max-width: 576px) {
  .container{
    margin-bottom: 97px;
  }
  .title {
    font-size: 27px;
    line-height: 32px;
  }
  .titleContainer::before {
    width: 250px;
    top: 40px;
    left: -52px;
  }
  .title::before,
  .title::after {
    top: 11px;
  }
  .info {
    display: block;
  }
  .text1,
  .text2{
    margin-bottom: 32px;
  }
  .text1,
  .text2,
  .text3 {
    font-size: 12px;
    line-height: 19px;
  }
  .img1,
.img2,
.img3{
    margin-bottom: 16px;
}
.text1 span,
.text2 span,
.text3 span{
 font-size: 22px;
 font-weight: 500;
}
}
