.container {
  margin: 0 auto 64px;
  padding: 0 24px 0;
  max-width: 1248px;
  width: 100%;
}
.titleContainer {
  margin: 0 auto 80px;
  position: relative;
  max-width: max-content;
}
.titleContainer::before {
  display: block;
  position: absolute;
  top: 90px;
  left: -30px;
  content: "";
  width: 1034px;
  height: 6px;
  background-color: var(--brown);
}
.title {
  text-align: center;
  position: relative;
  font-size: 60px;
  line-height: 90px;
  font-weight: 800;
  color: var(--dark-gray);
  text-transform: uppercase;
}
.title::before,
.title::after {
  display: block;
  position: absolute;
  top: 40px;
  content: "";
  width: 12px;
  height: 12px;
  background-color: var(--brown);
  border-radius: 50%;
}
.title::before {
  left: -20px;
}
.title::after {
  right: -20px;
}
.info {
  max-width: 1035px;
  width: 100%;
}

.infoTitle {
  margin-bottom: 16px;
  text-transform: uppercase;
  font-size: 27px;
  line-height: 32px;
  font-weight: 700;
  color: var(--dark-gray);
}

.infoTitle span {
  margin-right: 10px;
}
.infoText {
  margin-bottom: 32px;
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  font-family: var(--montserrat);
}
@media (max-width: 1081px) {
  .title {
    font-size: 50px;
  }
  .titleContainer::before {
    width: 865px;
  }
}
@media (max-width: 872px) {
  .title {
    line-height: 60px;
  }
  .title::before {
    left: 10px;
    top: 63px;
  }
  .title::after {
    right: -7px;
    top: 63px;
  }
  .titleContainer::before {
    top: 131px;
    left: 10px;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 40px;
  }
  .titleContainer::before {
    width: 687px;
    top: 62px;
    left: -21px;
  }
  .title::before,
  .title::after {
    top: 25px;
  }
  .title::before {
    left: -20px;
  }

  .title::after {
    right: -20px;
  }
}
@media (max-width: 711px) {
  .titleContainer::before {
    width: 100%;
    left: 0px;
    top: 125px;
  }
  .title::before,
  .title::after {
    top: 53px;
  }
  .title::before {
    left: -2px;
  }

  .title::after {
    right: 2px;
  }
}
@media (max-width: 576px) {
  .title {
    text-align: center;
    font-size: 27px;
    line-height: 32px;
  }
  .titleContainer {
    margin-bottom: 50px;
  }
  .titleContainer::before {
    width: 485px;
    top: 40px;
    left: -24px;
  }
  .title::before,
  .title::after {
    top: 12px;
  }
  .title::before {
    left: -25px;
  }

  .title::after {
    right: -25px;
  }
  .infoTitle {
    font-family: var(--montserrat);
    text-transform: initial;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }
  .infoText {
    margin-bottom: 16px;
    font-size: 12px;
    line-height: 19px;
  }
}
@media (max-width: 501px) {
  .titleContainer::before {
    width: 100%;
    top: 70px;
    left: -7px;
  }
  .title::before,
  .title::after {
    top: 28px;
  }
  .title::before {
    left: -8px;
  }

  .title::after {
    right: 9px;
  }
}
