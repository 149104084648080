.modal {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
}
.modal.active {
  opacity: 1;
  pointer-events: all;
}
.modalContent {
  text-align: left;
  border-radius: 20px;
  max-width: 697px;
  width: 100%;
  background-color: var(--brown);
  color: var(--white);
  transform: scale(0.5);
  transition: 0.4s all;
}

.modalContent.active {
  transform: scale(1);
}

.iconClose {
  position: absolute;
  right: 48px;
  top: 48px;
  font-size: 32px;
}

.close{
  position: absolute;
  top: 37px;
  right: 48px;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.close:before,
.close:after {
  content: "";
  position: absolute;
  top: 21px;
  left: 10px;
  width: 26px;
  height: 4px;
  background: var(--white);
}

.close:before {
  webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.close:after {
  webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@media (max-width: 390px) {
  .modalContent {
    width: 85vw;
  }
  .close{
    top: 17px;
    right: 20px;
  }
}
