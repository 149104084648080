.formWrapper {
  margin: 0 auto 205px;

  display: block;
  max-width: 1090px;
  width: 100%;
  text-align: center;
}
.formContainer {
  padding-left: 24px;
  padding-right: 24px;
}
.formContainer h2 {
  margin-bottom: 16px;
  font-size: 60px;
  font-weight: 800;
  text-transform: uppercase;
  color: var(--brown);
}

.formContainer p {
  margin-bottom: 32px;
  font-family: var(--montserrat);
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  max-width: 1034px;
  width: 100%;
}
.inputs {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
}

.input {
  width: 100%;
  border: none;
  outline: none;
}
.sendBtn {
  padding: 8px;
  max-width: 394px;
  width: 100%;
  background-color: var(--brown);
  border: none;
  border-radius: 20px;
  font-family: var(--montserrat);
  font-weight: 600;
  font-size: 24px;
  color: var(--white);
  cursor: pointer;
}

.sendBtn:hover {
  background-color: var(--brown-hover);
}
.sendBtn:focus {
  background-color: var(--brown-focused);
}
.sendBtn:disabled {
  background: var(--gray-disabled);
}
.popup {
  padding: 48px;
}
.popup h4 {
  margin-top: 0;
  margin-bottom: 32px;
  font-weight: 700;
  font-size: 27px;
  text-transform: uppercase;
}
.popup p {
  font-family: var(--montserrat);
  margin-bottom: 32px;
  color: var(--white);
}

.popup p:last-child {
  margin-top: 45px;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1081px) {
  .formWrapper {
    margin-bottom: 150px;
  }
  .formContainer h2 {
    font-size: 50px;
  }
}
@media (max-width: 768px) {
  .formContainer h2 {
    font-size: 40px;
  }
}
@media (max-width: 580px) {
  .inputs {
    flex-direction: column;
  }
}

@media (max-width: 390px) {
  .formWrapper {
    margin-bottom: 100px;
  }
  .formContainer {
    padding-left: 0;
    padding-right: 0;
  }

  .formContainer h2 {
    font-size: 27px;
  }
  .formContainer p {
    font-size: 16px;
  }
  .popup {
    padding: 24px;
  }
  .popup h4 {
    margin-bottom: 24px;
    max-width: 229px;
    width: 100%;
  }
  .popup p {
    font-size: 12px;
    margin-bottom: 16px;
  }
  .popup p:last-child {
    margin-top: 24px;
  }
}
