.headerContainer {
  margin: 0 auto 32px;
  padding: 0 20px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  max-width: 1280px;
}

@media (max-width: 1081px) {
  .headerContainer{
    grid-template-columns: 1fr 4fr;
  }
}
